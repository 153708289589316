<template>
    <div>
        <div class="page-header justify-content-start">
            <lable class="page-title">Give Proxy</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
         <div class="give-proxy_sec">
           <h4>Give Proxy</h4>
           <div>
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="Step 1" active> 
                  <div class="tab_content">
                    <h6>Select a user to give proxy to</h6>
                    <div class="user_list">
                      <input type="text" placeholder="Enter Name" class="form-control"> 
                      <div class="suggestion_list">
                        <ul>
                          <li>
                            <figure class="dp">
                              <span><img src="@/assets/images/pooja.svg"></span>
                              <figcaption>Pooja H</figcaption>
                            </figure>
                            <em><img src="@/assets/images/plus.svg"></em>
                          </li>
                          <li>
                            <figure class="dp">
                              <span><img src="@/assets/images/pooja.svg"></span>
                              <figcaption>Purnima Ramesh</figcaption>
                            </figure>
                            <em><img src="@/assets/images/plus.svg"></em>
                          </li>
                          <li>
                            <figure class="dp">
                              <span><img src="@/assets/images/pooja.svg"></span>
                              <figcaption>Piyush Shah</figcaption>
                            </figure>
                            <em><img src="@/assets/images/plus.svg"></em>
                          </li>
                          <li>
                            <figure class="dp">
                              <span><img src="@/assets/images/pooja.svg"></span>
                              <figcaption>Prabhas Rao</figcaption>
                            </figure>
                            <em><img src="@/assets/images/plus.svg"></em>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="tab_content_actions">
                      <button>CANCEL</button>
                      <button>APPLY CHANGES</button>                      
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Step 2">
                  <div class="tab_content">
                    <h6>Select a user to give proxy to</h6>
                    <div class="user_list">
                      <input type="text" placeholder="Enter Name" class="form-control"> 
                      <div class="selected_list">
                        <label>Selected user</label>
                        <ul>
                          <li>
                            <figure class="dp">
                              <span><img src="@/assets/images/pooja.svg"></span>
                              <figcaption>Pooja H</figcaption>
                            </figure>
                          </li> 
                        </ul>
                      </div>
                      <div class="assign_function">
                        <h6>Assign Function</h6>
                        <form>
                          <b-form-checkbox>Approve</b-form-checkbox>
                          <b-form-checkbox>On Hold</b-form-checkbox>
                          <b-form-checkbox>Archive</b-form-checkbox>
                          <b-form-checkbox>Raise Query</b-form-checkbox>
                          <b-form-checkbox>Raise Query</b-form-checkbox>
                          <b-form-checkbox>Flag - Urgent</b-form-checkbox>
                          <b-form-checkbox>Reply</b-form-checkbox> 
                        </form>
                      </div>
                    </div>
                    <div class="tab_content_actions">
                      <button>CANCEL</button>
                      <button>APPLY CHANGES</button>                      
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
         </div>
    </div>
</template>

<script> 
  export default {
    components: {
         
    },
 
  }
</script>